<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">Норма Подключение ресурсов</div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button type="success">
                                {{$t('message.save_and_close')}}
                            </el-button>
                            <el-button> {{$t('message.close')}} </el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="molad-content">
            <el-form
                ref="form"
                :model="form"
                size="small"
                class="inputBgNone bg_oq p20"
            >
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="Наименование ">
                            <el-input
                                v-model="form.name2"
                                placeholder="Наименование"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="8">
                        <el-form-item label="Услуги">
                            <el-select
                                class="w-100"
                                v-model="form.regionq"
                                placeholder="Услуги"
                            >
                                <el-option
                                    label="Все"
                                    value="shanghai"
                                ></el-option>
                                <el-option
                                    label="12-25"
                                    value="beijing"
                                ></el-option>
                                <el-option
                                    label="25-35"
                                    value="beijing"
                                ></el-option>
                                <el-option
                                    label="2-10"
                                    value="beijing4"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- end col -->

                    <!-- end col -->
                </el-row>

                <el-divider
                    content-position="left"
                    class="mt-2 mb-5"
                    @click="outerVisible = true"
                >
                    Добавить Ресурсы
                </el-divider>

                <table
                    class="table table-bordered table-hover table-layout_fixed"
                >
                    <tr>
                        <th>Наименование</th>
                        <th>Количество</th>

                        <th>Удалить</th>
                    </tr>
                    <tr>
                        <td>Pahta</td>
                        <td>
                            <el-input
                                v-model="form.name2"
                                placeholder="Наименование"
                                size="small"
                            ></el-input>
                        </td>

                        <td>
                            <el-button
                                type="danger"
                                icon="el-icon-delete"
                                circle
                            ></el-button>
                        </td>
                    </tr>
                </table>

                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-form-item>
                            <el-select
                                class="w-100"
                                v-model="form.regionq"
                                placeholder="Добавить позицию — введите наименование, код, штрихкод или артикул"
                            >
                                <el-option
                                    label="Все"
                                    value="shanghai"
                                ></el-option>
                                <el-option
                                    label="12-25"
                                    value="beijing"
                                ></el-option>
                                <el-option
                                    label="25-35"
                                    value="beijing"
                                ></el-option>
                                <el-option
                                    label="2-10"
                                    value="beijing4"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- end col -->
                </el-row>
                <!-- end el-row -->
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            items: [
                {
                    name3: "",
                    name2: "",
                },
            ],
        };
    },
};
</script>